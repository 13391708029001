const DEFAULT_CONFIG = {
  loop: false,
  volume: 1,
  muted: false,
  autoplay: false,
  preload: 'metadata',
  playList: [],
  safeBufferTime: 2,
  minPlayBufferTime: 1,
  resolution: '720p',
  dashboardHideDelay: 2500
}

export default DEFAULT_CONFIG
